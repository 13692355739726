import { fallbackLng } from "./lib/i18n/settings";

type SitesStruct = {
    defaultLang: string[];
    defaultLoc: string[];
    custom: Record<string, string[]>;
    ignoreFolders: string[];
    backends: Record<string, string>;
    hostPanelBackend: string;
    gtmCode: Record<string, string | undefined>;
    googleVerification: Record<string, string | undefined>;
};

export const SITES: SitesStruct = {
    //defaultLang: ["en", "cn", "es", "fr", "it", "pt", "ru", "tr"], for sitemap
    //defaultLoc: ["https://myroom24.com", "https://www-v2.myroom24.dev", "https://frontend-show.pages.dev/"], for sitemap
    defaultLang: ["en", "de", "cn", "es", "fr", "it", "pt", "ru", "tr"],
    defaultLoc: ["https://myroom24.com"],
    custom: {
        // first host/domain is treated as main - we generate sitemap.xml for it, others get prefixed filenames
       // de: ["https://myroom24.com", "https://preprod.myroom24.com", "https://show.myroom24.com", "http://localhost:8002"],
        //en: ["https://myroom24.com", "https://preprod.myroom24.com", "https://show.myroom24.com", "http://localhost:8002"], for sitemap
        en: ["https://myroom24.com"],
    },

    // local dev test - run with docker compose to test
    // custom: {
    //     // first host/domain is treated as main - we generate sitemap.xml for it, others get prefixed filenames
    //     de: ["http://localhost:8001", "https://preprod.myroom24.de", "https://myroom24.de"],
    //     en: ["http://localhost:8002", "https://preprod.myroom24.com"],
    // },

    // can also include forward slashes for deeper folders, e.g.: "blog/tag"
    ignoreFolders: [
        "billing",
        "checkout",
        "invoices",
        "messages",
        "payment",
        "reservations",
        "security",
        "user-profile",
        "press",
        "presse",
        "abrechnung",
        "facturation",
        "fatturazione",
        "выставление-счета",
        "fatura",
        "结算",
        "facturación",
        "kasse",
        "paiement",
        "pagamento",
        "finalizar",
        "oformlenie-zakaza",
        "odeme",
        "结帐",
        "pago",
        "rechnungen",
        "factures",
        "fatture",
        "счета",
        "faturalar",
        "发票",
        "facturas",
        "nachrichten",
        "messages",
        "messaggi",
        "сообщения",
        "mesajlar",
        "消息",
        "mensajes",
        "zahlung",
        "payer",
        "paga",
        "оплата",
        "ödemek",
        "付款",
        "pagar",
        "reservierungen",
        "réservations",
        "prenotazioni",
        "бронирования",
        "rezervasyonlar",
        "预订",
        "reservas",
        "sicherheit",
        "sécurité",
        "sicurezza",
        "безопасность",
        "güvenlik",
        "安全",
        "seguridad",
        "benutzerprofil",
        "profil-utilisateur",
        "profilo-utente",
        "профиль-пользователя",
        "kullanici-profil",
        "用户资料",
        "perfil-usuario",
        "presse",
        "presse",
        "stampa",
        "imprensa",
        "pressa",
        "basin",
        "新闻",
        "prensa",
        "email-verification",
        "reset-password",
        "privacy-policy",
        "datenschutzerklaerung",
        "politique-de-confidentialite",
        "informativa-sulla-privacy",
        "politica-de-privacidade",
        "politika-konfidencialnosti",
        "gizlilik-politikasi",
        "隐私政策",
        "politica-de-privacidad",
        "search",
        "suche",
        "rechercher",
        "ricerca",
        "pesquisa",
        "poisk",
        "arama",
        "搜索",
        "buscar",
        "cookie-policy",
        "cookie-richtlinie",
        "politique-de-cookies",
        "politica-dei-cookie",
        "politica-de-cookies",
        "politika-faylov-cookie",
        "cerez-politikasi",
        "cookie政策",
        "politica-de-cookies",
    ],
    backends: {
        en: "https://api.myroom24.com/",
        // de: "https://api.myroom24.de/",
    },
    hostPanelBackend: "https://api.myroom24.com/",
    gtmCode: {
        // de: process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER_DE,
    },
    googleVerification: {
        // de: process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_DE
    }
};

export const getLangFromHost = (host: string) => {
    for (const [lang, domains] of Object.entries(SITES.custom)) {
        if (domains.includes(host)) {
            return lang;
        }
    }
    return null;
};

export function getDomain(language: string) {
    let domain = "";
    let isCustomDomain = false;
    if (process.env.NEXT_PUBLIC_PRODUCTION_BUILD === "true") {
        // check if language is in SITES.custom and use first domain from there as link
        isCustomDomain = Object.keys(SITES.custom).includes(language);
        domain = SITES.custom[isCustomDomain ? language : fallbackLng][0];
        if (typeof window !== "undefined" && domain.split("://")[1].replaceAll("/", "") === window.location.host) {
            domain = "";
        }
    }
    if (!domain.endsWith("/")) {
        domain = domain + "/";
    }
    return { domain, isCustomDomain };
}

export function getBackend(lang: string) {
    let backendHost = process.env.NEXT_PUBLIC_BACKEND_HOST;
    if (process.env.NEXT_PUBLIC_PRODUCTION_BUILD === "true") {
        backendHost = SITES.backends[Object.keys(SITES.backends).includes(lang) ? lang : fallbackLng];
    }
    return backendHost;
}
